import { IconProps } from '@/utilities/types/Icon';

const Truck = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 25 25"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        clipPath="url(#a)"
      >
        <path
          strokeLinejoin="round"
          d="M16.375 16V6.2c0-2.12 0-2.68-.218-3.108a2 2 0 0 0-.874-.874C14.855 2 14.295 2 13.175 2h-7.6c-1.12 0-1.68 0-2.108.218M16.375 16h-10.8c-1.12 0-1.68 0-2.108-.218M16.375 16h4.4c.56 0 .84 0 1.054-.109a1 1 0 0 0 .437-.437c.109-.214.109-.494.109-1.054v-2.737c0-.245 0-.367-.028-.482a1 1 0 0 0-.12-.29c-.061-.1-.148-.187-.32-.36L19.843 8.47c-.173-.173-.26-.26-.36-.322a1 1 0 0 0-.29-.12C19.08 8 18.957 8 18.712 8h-2.337m-7 10.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm11 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
        />
        <path d="M7.375 6h-5M8.375 9h-5M10.375 12h-9" />
      </g>
      <defs>
        <clipPath>
          <path fill="currentColor" d="M.375 0h24v24h-24z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Truck;
